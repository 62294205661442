/* Loader.css */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: black;
}

.loader-logo {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
  animation: bounce 2s infinite;
}

.loader-text {
  font-size: 24px;
  font-weight: bold;
  color: white;
  animation: fadeIn 2s infinite;
}

.loader-animation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 5px;
  animation: bounce 0.5s alternate infinite ease;
}

.dot1 {
  animation-delay: 0.1s;
}

.dot2 {
  animation-delay: 0.3s;
}

.dot3 {
  animation-delay: 0.5s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
