.contact-us__content {
  max-width: 495px;
}
@media (max-width: 991px) {
  .contact-us__content .sub-title {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 575px) {
  .contact-us__content .sub-title {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 991px) {
  .contact-us__content .title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 575px) {
  .contact-us__content .title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 480px) {
  .contact-us__content .title {
    font-size: 26px;
    line-height: 34px;
  }
}
.contact-us__item {
  max-width: 254px;
}
.contact-us__item-wrapper {
  margin-bottom: -40px;
}
@media (min-width: 992px) {
  .contact-us__item-wrapper {
    margin-left: -11px;
  }
}
.contact-us__item-wrapper .col-6:nth-child(even) .contact-us__item {
  margin-left: auto;
}
.contact-us__item .icon {
  font-size: 28px;
  position: relative;
  top: -8px;
  padding-right: 10px;
}
.contact-us__item .icon .icon-email {
  font-size: 20px;
}
@media (max-width: 575px) {
  .contact-us__item .title {
    font-size: 16px;
    line-height: 26px;
  }
}
.contact-us__item ul li a {
  font-size: 16px;
  line-height: 28px;
  color: #243342;
  font-family: "Lato", sans-serif;
}
.contact-us__item ul li a:hover,
.contact-us__item ul li a:focus {
  color: #4069ff;
}

.contact-form #contact-map {
  width: 100%;
  height: 300px;
}
@media (min-width: 768px) {
  .contact-form #contact-map {
    height: 130%;
    position: absolute;
  }
}
.contact-form .contact-form {
  max-width: 550px;
  position: relative;
  background: #fff;
  float: right;
}
.contact-form .contact-form__header .sub-title {
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 991px) {
  .contact-form .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .contact-form .contact-form__header .title {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 767px) {
  .contact-form .contact-form__header .title {
    font-size: 26px;
    line-height: 38px;
  }
}
.contact-form .contact-form .single-personal-info {
  margin-bottom: 20px;
}
.contact-form .contact-form input,
.contact-form .contact-form textarea {
  outline: none;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  max-width: 100%;
  font-weight: 400;
  line-height: 26px;
  background-color: #fff;
  box-sizing: border-box;
}
.contact-form .contact-form input::placeholder,
.contact-form .contact-form textarea::placeholder {
  color: #243342;
  font-size: 15px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  line-height: 26px;
}
.contact-form .contact-form textarea {
  resize: none;
  height: 120px;
}
.contact-form .contact-form .theme-btn {
  margin-top: -8px;
}

.contact-form iframe {
  width: 100%;
  height: 100%;
}
