@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Nunito:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inknut+Antiqua:wght@300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
body {
  font-family: "Poppins", sans-serif !important;
  color: #444444;
  background-color: black !important;
}

a:hover {
  color: #ffd584;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}
html {
  scroll-behavior: smooth;
}
/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #7ed957;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #151515;
  line-height: 0;
}

.back-to-top:hover {
  background: #151515;
}

.back-to-top:hover i {
  color: #7ed957;
}
.primary-color {
  color: #7ed957;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #151515;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 0px);
  left: calc(50% - 30px);
  border: 6px solid #7ed957;
  border-top-color: #151515;
  border-bottom-color: #151515;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
  # Header2
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
}
header.fixed-top {
  background-color: tranparent !important;
}
header.fixed-top.scrolled {
  background-color: #000 !important;
}
#header.header-scrolled,
#header.header-inner-pages {
  background: rgba(0, 0, 0, 0.8);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.offcanvas-header .navbar-brand img {
  width: 140px;
}
#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #7ed957;
}

#header .logo img {
  max-height: 80px;
}

/*--------------------------------------------------------------
  #  Get Startet Button
  --------------------------------------------------------------*/
.get-started-btn {
  color: #fff;
  border-radius: 4px;
  padding: 7px 25px 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 2px solid #7ed957;
  background-color: inherit;
  text-decoration: none;
  text-transform: uppercase;
}

.get-started-btn:hover {
  background: #7ed957;
  color: #343a40 !important;
}

@media (max-width: 992px) {
  .get-started-btn {
    padding: 7px 20px 8px 20px;
    margin-right: 15px;
  }
  .navbar .offcanvas-body a {
    padding: 15px 8px;
  }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #7ed957;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #151515;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  background-color: #7ed957;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
.featured-main {
  background: linear-gradient(0deg, #0a0a0a 4%, #000000 100%) 0;
}
.featured-main .featured-logo img {
  max-width: 200px;
  max-height: 100px;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
/* Theme button */
.theme-btn {
  border: 0;
  z-index: 1;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  overflow: hidden;
  position: relative;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  padding: 15px 28px;
  background-color: #7ed957;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.theme-btn:hover {
  background-color: #00c3d6;
}
.theme-btn:after {
  top: 50%;
  left: 50%;
  height: 0%;
  width: 100%;
  content: "";
  z-index: -1;
  position: absolute;
  background: #00c3d6;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #151515;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #151515;
  background-color: #7ed957;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
  color: #151515;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  background-color: #7ed957;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
  # header-box Section
  --------------------------------------------------------------*/
.wrapper {
  position: relative;
}
.wrapper .wrapper-bg video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  min-width: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
}
#header-box {
  width: 100%;
  height: 100vh;
  position: relative;
}

#header-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#header-box .container {
  position: relative;
  padding-top: 74px;
  text-align: center;
}

#header-box h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 900;
  line-height: 64px;
  color: #fff;
  font-family: "Nunito", sans-serif;
}

.theme-color {
  color: #7ed957;
  -webkit-text-stroke: 2px #7ed957;
  -webkit-text-fill-color: transparent;
}

#header-box h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 24px;
}

#header-box .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}
#header-box .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgb(0 0 0 / 10%);
  transform: translateY(-10px);
}

#header-box .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #7ed957;
}

#header-box .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

#header-box .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#header-box .icon-box h3 a:hover {
  color: #7ed957;
}

#header-box .icon-box:hover {
  border-color: #7ed957;
}

@media (min-width: 1024px) {
  #header-box {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  .featured-main .featured-logo img {
    max-width: 100px;
    max-height: 60px;
  }
  #header-box {
    height: auto;
  }
  .wrapper .wrapper-bg video {
    height: 100%;
  }
  #header-box h1 {
    font-size: 28px;
    line-height: 36px;
  }

  #header-box h2 {
    font-size: 20px;
    line-height: 24px;
  }
  .about .content {
    padding-top: 120px !important;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 30px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Nunito", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
  color: #151515;
}
/* Talent solution process */

.process-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-main {
  width: 100%;
  padding: 25px;
}

.timeline-main .timeline-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.timeline-main .timeline-wrap::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: calc(100% - 10px);
  background-color: #00283a;
  animation: line 5s linear;
}

@media (max-width: 767px) {
  .timeline-main .timeline-wrap::before {
    left: 95%;
  }
}

.timeline-main .timeline-wrap .timeline-card {
  width: 50%;
  margin-right: auto;
  position: relative;
}

@media (max-width: 767px) {
  .timeline-main .timeline-wrap .timeline-card {
    width: 95%;
  }
}

.timeline-main .timeline-wrap .timeline-card::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #00283a;
  animation: fadeIn 1.5s;
  animation-fill-mode: both;
}

.timeline-main .timeline-wrap .timeline-card .timeline-card-wrap {
  height: 100%;
  margin-right: 35px;
  background-color: #00283a;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  animation: fadeInRight 2.5s;
  animation-fill-mode: both;
}

@media (max-width: 767px) {
  .timeline-main .timeline-wrap .timeline-card .timeline-card-wrap {
    padding: 20px;
  }
}

.timeline-main .timeline-wrap .timeline-card .timeline-card-wrap::before {
  content: "";
  position: absolute;
  left: 30px;
  top: -8px;
  height: 8px;
  width: calc(100% - 60px);
  background-color: #00283a;
  border-radius: 5px 5px 0 0;
  opacity: 0.3;
}

.timeline-main .timeline-wrap .timeline-card .timeline-card-wrap::after {
  content: "";
  position: absolute;
  top: 10px;
  right: -8px;
  width: 20px;
  height: 20px;
  background-color: #00283a;
  border-radius: 5px;
  transform: rotate(45deg);
}

.timeline-main
  .timeline-wrap
  .timeline-card
  .timeline-card-wrap
  .card-head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .timeline-main
    .timeline-wrap
    .timeline-card
    .timeline-card-wrap
    .card-head-wrap {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.timeline-main
  .timeline-wrap
  .timeline-card
  .timeline-card-wrap
  .timeline-card-head {
  font-size: 28px;
  line-height: 38px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .timeline-main
    .timeline-wrap
    .timeline-card
    .timeline-card-wrap
    .timeline-card-head {
    font-size: 18px;
    line-height: 28px;
  }
}

.timeline-main
  .timeline-wrap
  .timeline-card
  .timeline-card-wrap
  .timeline-card-subhead {
  font-size: 28px;
  line-height: 26px;
  font-weight: 800;
  margin-bottom: 0;
  font-style: italic;
  color: #7ed957;
  -webkit-text-stroke: 2px #7ed957;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 767px) {
  .timeline-main
    .timeline-wrap
    .timeline-card
    .timeline-card-wrap
    .timeline-card-subhead {
    font-size: 14px;
    line-height: 24px;
  }
}

.timeline-main
  .timeline-wrap
  .timeline-card
  .timeline-card-wrap
  .timeline-card-text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #919ca1;
  margin: 25px 0 0;
}

@media (max-width: 767px) {
  .timeline-main
    .timeline-wrap
    .timeline-card
    .timeline-card-wrap
    .timeline-card-text {
    font-size: 12px;
    line-height: 22px;
  }
}

.timeline-main .timeline-wrap .timeline-card:nth-child(even) {
  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: 0;
  }
}

.timeline-main .timeline-wrap .timeline-card:nth-child(even)::after {
  right: unset;
  left: -10px;
  animation-delay: 2.6s;
}

.timeline-main
  .timeline-wrap
  .timeline-card:nth-child(even)
  .timeline-card-wrap {
  margin-right: 0;
  margin-left: 35px;
  animation: fadeInLeft 2.5s;
  animation-fill-mode: both;
  animation-delay: 2.5s;
}

.timeline-main
  .timeline-wrap
  .timeline-card:nth-child(even)
  .timeline-card-wrap::after {
  right: unset;
  border-left: none;
  left: -8px;
  border-right: 10px solid #00283a;
}

@keyframes line {
  0% {
    height: 0;
  }
  100% {
    height: calc(100% - 10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about {
  background-color: #000000;
  padding-bottom: 60px;
}

.about .content h3 {
  font-weight: 700;
  font-size: 28px;
  font-family: "Nunito", sans-serif;
}
.about .video-section video {
  width: 100%;
  min-width: 100%;
  object-position: center;
  object-fit: cover;
}
.about .services-box {
  box-shadow: 1px 0px 15px -5px #00c3d6;
  background-color: #000000;
}
.about .services-box:hover {
  margin-bottom: 3px;
  color: white;
  box-shadow: 1px 0px 20px -5px #00c3d6;
}
.about .services-box:hover a {
  color: white !important;
}

.features {
  background-color: #000000;
}
.community {
  background-color: #000000;
}
/*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
.clients {
  padding-top: 20px;
  background-color: #000000;
}
.clients .swiper-slide {
  padding-block: 20px;
  justify-content: center;
  display: flex !important;
}
.clients .swiper-slide img {
  transition: 0.3s;
}

.clients .swiper-slide img:hover {
  filter: none;
  scale: 1.4;
}

.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  background-color: #ddd;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #7ed957;
}

/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features {
  padding-top: 20px;
}

.features .icon-box {
  padding-left: 15px;
  box-shadow: 1px 0px 15px -5px #00c3d6;
}

.features .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 10px;
  color: white;
}

.features .icon-box i {
  font-size: 48px;
  float: left;
  padding-inline: 2rem;
}

.features .icon-box p {
  font-size: 15px;
  color: #848484;
}

.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services {
  background-color: #000000;
}
.services .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #151515;
  margin-block: 10px;
  height: 26rem;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: 0.3s;
}

.services .icon-box .icon i {
  color: #151515;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: white;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 a:hover {
  color: #7ed957;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: white;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url("../src/assets/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 60px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 10px;
  border: 2px solid #fff;
  color: #fff;
}

.cta .cta-btn:hover {
  background: #7ed957;
  border-color: #7ed957;
  color: #151515 !important;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
@keyframes move-right {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(20px, 0);
    transform: translate(20px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.our-company {
  background-color: #000000;
}
.our-company .container {
  position: relative;
}
.our-company .container:after {
  top: -587px;
  content: "";
  left: -564px;
  z-index: -1;
  width: 2048.04px;
  height: 1285.06px;
  position: absolute;
  background-size: auto;
  background-repeat: no-repeat;
  -webkit-animation: scale 3s linear infinite;
  animation: scale 3s linear infinite;
  background-image: url(assets/about/why-counter-overly-3.png);
}

.our-company__meida {
  overflow: hidden;
  position: relative;
}
.our-company__meida.border-radius {
  margin-top: 60px;
  padding-bottom: 6px;
  border-radius: 20px 20px 0px 0px;
}
.our-company .service-box {
  box-shadow: 1px 0px 15px -5px #7ed957;
  padding: 10px;
  color: white;
  font-size: 14px;
}
@media (max-width: 405px) {
  .featured-main .featured-logo img {
    max-width: 40px !important;
    max-height: 40px;
  }
}
@media (max-width: 575px) {
  .featured-main .featured-logo img {
    max-width: 60px !important;
    max-height: 50px;
  }

  .our-company__meida.border-radius {
    margin-top: 25px;
  }
  .navbar a,
  .navbar a:focus {
    font-size: 13px;
    font-weight: 500;
    padding: 8px;
  }
}
@media (max-width: 575px) {
  .our-company__meida img {
    width: 100%;
  }
}
.our-company__meida .horizental-bar {
  left: 10px;
  width: 232px;
  height: 15px;
  bottom: 0px;
  max-width: 100%;
  position: absolute;
  background: #7ed957;
  -webkit-animation: move-right 3s linear infinite;
  animation: move-right 3s linear infinite;
}
@media (min-width: 1200px) {
  .our-company__content {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .our-company__content .title {
    font-size: 42px;
    line-height: 56px;
  }
}
@media (max-width: 991px) {
  .our-company__content .title {
    font-size: 36px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .our-company__content .title {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .our-company__content .title {
    font-size: 28px;
    line-height: 42px;
  }
}
.our-company__content .title span {
  font-weight: 300;
}

.years-experience {
  background: #7ed957;
  padding: 20px 15px 31px;
  border-radius: 0px 0px 20px 20px;
}
.years-experience.years-experience_tow {
  border-radius: 0px 0px 0px 0px;
  background: #fff5e5;
}
.years-experience.years-experience_tow .icons i {
  font-size: 50px;
  margin-right: 30px;
}
.years-experience.years-experience_tow .number {
  color: #79db54;
}
.years-experience.years-experience_tow .number sup {
  color: #79db54;
}
.years-experience.years-experience_tow h5 {
  color: #243342;
}
.years-experience .number {
  font-size: 64px;
  font-weight: 700;
  line-height: 60px;
}
@media (max-width: 767px) {
  .years-experience .number {
    font-size: 45px;
  }
}
.years-experience .number sup {
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
}
@media (max-width: 767px) {
  .years-experience .number sup {
    font-size: 35px;
  }
}

.counts .content {
  padding: 30px 0;
}

.counts .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #151515;
}

.counts .content p {
  margin-bottom: 0;
}

.counts .content .count-box {
  padding: 20px 0;
  width: 100%;
}

.counts .content .count-box i {
  display: block;
  font-size: 36px;
  color: #7ed957;
  float: left;
}

.counts .content .count-box span {
  font-size: 36px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  color: #7ed957;
  margin-left: 50px;
}

.counts .content .count-box p {
  padding: 15px 0 0 0;
  margin: 0 0 0 50px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #3b3b3b;
}

.counts .content .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #3b3b3b;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .content .count-box a:hover {
  color: #626262;
}

.counts .image {
  background: url("../src/assets/counts-img.jpg") center center no-repeat;
  background-size: cover;
  min-height: 400px;
}

@media (max-width: 991px) {
  .counts .image {
    text-align: center;
  }

  .counts .image img {
    max-width: 80%;
  }
}

@media (max-width: 667px) {
  .counts .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  background: url("../src/assets/testimonials-bg.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.testimonials::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.testimonials .section-header {
  margin-bottom: 40px;
}
header .fixed-top {
  background-color: #00000087;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  text-align: center;
  color: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #ddd;
  margin: 0 0 15px 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.6);
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #eee;
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #7ed957;
  opacity: 1;
}

@media (min-width: 1024px) {
  .testimonials {
    background-attachment: fixed;
  }
}

@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
.team {
  background: black;
  padding: 60px 0;
}

.team .member {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 5px;
  background: #151515;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.team .member .member-img {
  position: relative;
  overflow: hidden;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  transition: ease-in-out 0.3s;
  color: #484848;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.team .member .social a:hover {
  color: #151515;
  background: #7ed957;
}

.team .member .social i {
  font-size: 18px;
  line-height: 0;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: white;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
  # Training page header
  --------------------------------------------------------------*/
.training-tutors {
  background-image: url(./assets/Training-page/tutorsbg.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
}
.top-header-training-bg {
  background-image: url(./assets/Training-page/header-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
}
.courses-main-bg {
  background-image: url(./assets/Training-page/courses-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
}

.training-main {
  background: linear-gradient(rgb(0 0 0 / 80%), rgb(0 0 0)),
    url(../src/assets/bg-d.jpg) fixed 100% !important;
  background-size: cover;
  background-position: center;
  color: white;
}
.training-main .icon-box {
  text-align: center;
  border: 1px solid #ebebeb;
  padding: 20px 20px;
  transition: all ease-in-out 0.3s;
  background: #151515;
  margin-block: 10px;
  height: auto;
}
.training-main .icon-box:hover {
  color: #7ed957;
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}
.intructors .bg-dark-box {
  background-color: #151515;
}
/*--------------------------------------------------------------
  # Detail page header
  --------------------------------------------------------------*/

.detail-page-header {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url(../src/assets/techbg.jpg) fixed center;
  background-size: cover;
  color: white;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 400;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: white;
  content: "/";
}

.blog-header {
  background: url(./assets/Blogs/blog-title-bg.png);
}
.blog-post .desc {
  color: #6c6c72;
}
.read-more {
  background-color: #faf1b3;
  color: #eb2030;
  padding: 5px 15px;
  border-radius: 15px;
  font-weight: 500;
  text-decoration: none;
}
.recent-blogs .first-blog img {
  height: 230px;
  object-fit: cover;
}
@media (max-width: 992px) {
  .featured-main .featured-logo img {
    max-width: 120px;
  }

  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #151515;
  border-bottom: 1px solid #222222;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
}

#footer .footer-top .footer-info h3 span {
  color: #7ed957;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #292929;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #7ed957;
  color: #151515 !important;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #7ed957;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #7ed957;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #7ed957;
  color: #151515;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #ffcd6b;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.flip-card {
  transition: all ease-in-out 0.3s;
  cursor: pointer;
  width: 100%;
  height: 18rem;
  perspective: 1000px;
}

.footer-1 .social-profile ul li a {
  padding: 0;
  width: 36px;
  height: 36px;
  color: #fff;
  display: block;
  font-size: 15px;
  line-height: 32px;
  text-align: center;
  background-color: transparent;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  -ms-border-radius: 500px;
  border-radius: 500px;
  -webkit-transition: all all 0.3s ease ease-in-out;
  -moz-transition: all all 0.3s ease ease-in-out;
  -ms-transition: all all 0.3s ease ease-in-out;
  -o-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease;
  transition: all 0.3s ease;
  border: 1.5px solid rgba(226, 233, 255, 0.15);
}
.footer-1 .social-profile ul li a:hover,
.footer-1 .social-profile ul li a:focus {
  color: #fff;
  background-color: #44e7e2;
  -webkit-transform: translate(0, -5px);
  -moz-transform: translate(0, -5px);
  -ms-transform: translate(0, -5px);
  -o-transform: translate(0, -5px);
  transform: translate(0, -5px);
  -webkit-transition: all all 0.3s ease ease-in-out;
  -moz-transition: all all 0.3s ease ease-in-out;
  -ms-transition: all all 0.3s ease ease-in-out;
  -o-transition: all all 0.3s ease ease-in-out;
  transition: all all 0.3s ease;
  transition: all 0.3s ease;
  border: 1.5px solid rgba(226, 233, 255, 0.15);
  transform: translate(0, -5px);
}
.footer-1 .social-profile ul {
  gap: 10px;
  flex-wrap: wrap;
  display: inline-flex;
  flex-direction: inherit;
  justify-content: center;
}
.footer-1 ul li {
  text-decoration: none;
  list-style: none;
}
.footer-1 a {
  text-decoration: none;
}
.footer-1 .single-footer-wid ul {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.footer-2 {
  background-image: url(./assets/images/footer-bg-2.png);
  z-index: 1;
  position: relative;
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1199px) {
  .footer-2 {
    padding-top: 80px;
  }
}
@media (max-width: 991px) {
  .footer-2 {
    padding-top: 60px;
  }
}
@media (max-width: 767px) {
  .footer-2 {
    padding-top: 50px;
  }
}
.footer-2:after {
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(14, 10, 1, 0) -29.09%,
    #0e0a01 42.92%
  );
}
.footer-2 .footer-top__cta-content {
  max-width: 781px;
}
.footer-2 .footer-top__cta-content-wrapper {
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
}
.footer-2 .footer-top__cta-content h2 {
  font-size: 64px;
  line-height: 78px;
  font-weight: 700;
}
@media (max-width: 1199px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 54px;
    line-height: 68px;
  }
}
@media (max-width: 991px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 40px;
    line-height: 54px;
  }
}
@media (max-width: 380px) {
  .footer-2 .footer-top__cta-content h2 {
    font-size: 35px;
    line-height: 49px;
  }
}
.footer-2 .footer-top__cta-content .description {
  max-width: 694px;
}
.footer-2 .footer-top__cta-content .description p {
  font-size: 18px;
  line-height: 30px;
}
.footer-2 .newsletter_widget .newsletter_box.newsletter_box_2 {
  border: none !important;
  background: #1e1b15 !important;
  border-radius: 2px !important;
}
.footer-2 .newsletter_widget .newsletter_box form input {
  border: none;
  background: #1e1b15;
}
.footer-2 .newsletter_widget .newsletter_box form button.btn-yellow {
  background-color: #79db54;
}
.footer-2 .newsletter_widget .newsletter_box form button.btn-yellow:hover {
  background: #44e7e2;
}
.footer-2 .contact-wrapper {
  background: #7ed957;
}
.footer-2 .contact-wrapper .wid-contact ul li .icon i {
  color: #79db54;
}
.footer-2 .contact-wrapper .wid-contact ul li .icon i:hover,
.footer-2 .contact-wrapper .wid-contact ul li .icon i:focus {
  background: #44e7e2;
}
.footer-2 .social-profile ul li a {
  border-color: white;
}
.footer-2 .social-profile ul li a:hover,
.footer-2 .social-profile ul li a:focus {
  background: #46e1db;
  border-color: #000000;
  color: #000 !important;
}
.footer-1 .single-footer-wid ul li a {
  color: #fff;
  display: block;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
}
.footer-2 .single-footer-wid ul li:hover a,
.footer-2 .single-footer-wid ul li:focus a {
  color: #44e7e2;
}
.footer-bottom a {
  color: #79db54;
}
.footer-bottom {
  color: rgba(255, 255, 255, 0.705);
}

/* ------------------------------------------------------------------
   Template Default Button, Input Styles - Value Change with Your's Need
 -------------------------------------------------------------------- */
.theme-btn {
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-block;
  padding: 18px 37.8px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-color: #44e7e2;
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.theme-btn:after {
  background: #79db54;
}
.theme-btn.btn__2 {
  z-index: 1;
  overflow: hidden;
  position: relative;
}
.theme-btn.btn__2:after {
  background: #ef0548 !important;
  top: 50%;
  left: 50%;
  content: "";
  width: 100%;
  height: 0%;
  z-index: -1;
  position: absolute;
  transition: all 0.6s ease;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.theme-btn.btn__2:hover,
.theme-btn.btn__2:focus {
  color: #fff !important;
}
.theme-btn.btn__2:hover:after,
.theme-btn.btn__2:focus:after {
  height: 380%;
}
@media (max-width: 900px) {
  .theme-btn.btn_sm__2 {
    padding: 12px 25px;
  }
}
@media (max-width: 767px) {
  .theme-btn {
    padding: 17px 30px;
    font-size: 14px;
  }
}
@media (max-width: 580px) {
  .theme-btn {
    padding: 16px 26px;
  }
}
@media (max-width: 480px) {
  .theme-btn {
    padding: 15px 25px;
  }
}
.theme-btn i {
  font-size: 12px;
  margin-left: 10px;
}
.theme-btn.btn-white {
  color: #0e1e36;
  background-color: #fff;
}
.theme-btn.btn-white:after {
  background: #79db54;
}
.theme-btn:hover,
.theme-btn:focus {
  color: #fff;
  background-color: #79db54;
}
.theme-btn.btn-sm {
  padding: 10px 29px;
}
.theme-btn.btn-yellow {
  background-color: #79db54;
}
.theme-btn.btn-yellow:after {
  background: #44e7e2;
}
.theme-btn.btn-yellow:hover,
.theme-btn.btn-yellow:focus {
  background: #44e7e2;
  color: black !important;
}
.theme-btn.btn-md {
  padding-top: 20px;
  padding-bottom: 20px;
}
.theme-btn.btn-red {
  background-color: #ef0548;
}
.theme-btn.btn-red:after {
  background: #79db54;
}
.theme-btn.btn-red:hover,
.theme-btn.btn-red:focus {
  background-color: #ef0548;
}
.theme-btn.btn-black {
  padding: 8px 79.1px;
  background-color: #0e0a01;
}
.theme-btn.btn-black:after {
  background: #44e7e2;
}
.theme-btn.btn-black:hover,
.theme-btn.btn-black:focus {
  background: #44e7e2;
}
.theme-btn.btn-transparent {
  color: #79db54;
  padding: 13.5px 31.1px;
  background: transparent;
  border: 1.5px solid rgba(255, 156, 0, 0.3);
}
.theme-btn.btn-transparent i {
  margin: 0 10px 0 0;
}
.theme-btn.btn-transparent:after {
  background: #44e7e2;
}
.theme-btn.btn-transparent:hover,
.theme-btn.btn-transparent:focus {
  color: #000;
  background: #44e7e2;
  border-color: #44e7e2;
}
.theme-btn.btn-border {
  color: #243342;
  padding: 13.5px 26.5px;
  background: transparent;
  border: 1.5px solid rgba(36, 51, 66, 0.5);
}
.theme-btn.btn-border:after {
  background: #243342;
}
.theme-btn.btn-border:hover,
.theme-btn.btn-border:focus {
  color: #fff;
  background: #243342;
}
.theme-btn.btn-yellow-transparent {
  color: #79db54;
  padding: 13.5px 31.1px;
  background: transparent;
  border: 1.5px solid rgba(255, 156, 0, 0.3);
}
.theme-btn.btn-yellow-transparent:after {
  background: #44e7e2;
}
.theme-btn.btn-yellow-transparent:hover,
.theme-btn.btn-yellow-transparent:focus {
  color: #fff;
  border-width: 1px;
  padding: 14px 31.6px;
  border-color: #44e7e2;
  background: transparent;
}
.theme-btn:after {
  top: 50%;
  left: 50%;
  content: "";
  width: 100%;
  height: 0%;
  z-index: -1;
  position: absolute;
  transition: all 0.6s ease;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.theme-btn:hover:after,
.theme-btn:focus:after {
  height: 380%;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back-consult,
.flip-card-back-staffing,
.flip-card-back-training,
.flip-card-back-engineering,
.flip-card-back-blockchain,
.flip-card-back-science,
.flip-card-back-sales,
.flip-card-back-data {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.flip-card-front {
  padding: 60px 20px;
  color: white;
  background: #151515;
  border: 1px solid #ebebeb;
}

.flip-card-back-consult {
  padding: 60px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/data-eng.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-staffing {
  padding: 60px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/business-analyst.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-training {
  padding: 60px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/training.png") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-engineering {
  padding: 20px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/data-eng-bg.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-blockchain {
  padding: 20px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/block-bg.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-science {
  padding: 20px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/data-sci-bg.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-sales {
  padding: 20px 20px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/business-analyst.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.flip-card-back-data {
  padding: 40px;
  background: linear-gradient(rgba(2, 2, 2, 0.9), rgba(0, 0, 0, 0.9)),
    url("../src/assets/analytics.svg") 100% no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border: 1px solid #ebebeb;
}
.intructor-videos-main {
  background-color: #e2e2e2;
}
/* Mentors */
.mentor-box,
.plan-box {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to bottom, #289500 50%, #00b9c5 50%) 1;
}
@media screen and (min-width: 769px) {
  .mentor-img img {
    transform: scale(1.3);
  }
}

/* Spinner */

.spinner {
  position: absolute;
  bottom: -8rem;
  right: 0rem;
  z-index: 1;
}
#html-spinner {
  width: 290px;
  height: 290px;
  background: url(../src/assets/circle-blue.png);
  background-repeat: no-repeat;
  /* border:4px solid #fcd779;
    border-top:4px solid white; */
  /* border-radius:50%; */
}

#html-spinner,
#svg-spinner {
  -webkit-transition-property: -webkit-transform;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate;
  -moz-animation-duration: 6s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  transition-property: transform;
  animation-name: rotate;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* contact form style */

.form-group {
  height: 60px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}
.form .contact-input-field {
  width: 100%;
  height: 100%;
  color: black;
  padding-top: 25px;
  border: none;
  outline: none;
  background: #212529;
  border-bottom: 2px solid white;
}
.form label {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0%;
  pointer-events: none;
  border-bottom: 1px solid #212529;
}
.form-check-input:checked {
  background-color: #7ed957 !important;
  border-color: #7ed957 !important;
}
.form label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid #7ed957;
  bottom: -1px;
  left: 0px;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease;
}
.label-name {
  color: black;
}
.form input:focus + .label-name .content-name,
.form input:valid + .label-name .content-name {
  transform: translateY(-150%);
  font-size: 14px;
  color: #7ed957;
}
.form input:focus + .label-name::after,
.form input:valid + .label-name::after {
  transform: translateX(0%);
}
.made {
  position: absolute;

  bottom: 10%;
}
.made i {
  color: red;
}
.mentors-main .slick-dots li button:before {
  color: rgba(0, 0, 0, 0.527);
}
.slick-dots li button:before {
  color: white;
}
.slick-dots li.slick-active button:before {
  color: #7ed957;
}

.corporate-vid {
  background-image: url(./assets/Videos/video-bg.svg);
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.25);
}
.choose-us {
  background-image: url(./assets/gpt8.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.25);
}
.development-bg {
  background-image: url(./assets/web\ and\ app\ dev/pattern.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.why-techscope-bg {
  background: linear-gradient(#dfefd9, #279500);
}
